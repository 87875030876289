import { css } from '@emotion/core';
import { mq, variables } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: variables.greenColor,
    fontSize: ['16px', null, '18px'],
    'svg' : {
      marginBottom: '2px'
    }
  })),
}