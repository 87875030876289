const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  darkGrayColor : '#3b3b3b',
  greenColor : '#40874b',
  purpleColor : '#8d4a96',
  lightGray : '#ebebeb'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"adobe-handwriting-frank",serif',

  primaryColor: colors.greenColor,
  fontColor: colors.darkGrayColor,
  bgColor: colors.lightGray,

  ...colors,
  ...overloading
}

export default variables;